<template>
  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h1
                  class="custom-header-title"
                >
                  Preview Invoice {{ invoice.invoiceID }}
                </h1>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <section class="invoice-preview-wrapper">
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <div v-if="invoice.attachments && invoice.attachments.length">
            <div v-if="invoice.attachments.length > 1">
              <b-card
                class="mb-0"
              >
                <b-row class="invoice-preview">
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-left"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == 1"
                      @click="changeFileIndex(currentFileIndex - 1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="pt-25"
                        size="18"
                      />
                      <span class="align-middle">Prev</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-center"
                  >
                    <b-dropdown
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      split
                    >
                      <template #button-content>
                        <feather-icon
                          icon="FileIcon"
                          size="18"
                          class="align-middle mr-1"
                        />
                        <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ invoice.attachments ? ((invoice.attachments.length).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                      </template>
                      <b-dropdown-item
                        v-for="(file, key) in invoice.attachments"
                        :key="key"
                        @click="changeFileIndex(key + 1)"
                      >
                        {{ file ? file.name : '' }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-right"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == (invoice.attachments ? (invoice.attachments.length) : 1)"
                      @click="changeFileIndex(currentFileIndex + 1)"
                    >
                      <span class="align-middle">Next</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="pt-25"
                        size="18"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </div>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <iframe
                v-show="showPDF"
                id="attachmentpdfvp"
                width="100%"
                height="1145"
              />
              <b-img
                v-show="!showPDF"
                :src="imageSrc"
                fluid-grow
                alt="Fluid-grow image"
              />
            </b-card>
          </div>
          <div v-else>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <div
                style="background: #fff;"
              >
                <b-col>
                  <div class="empty_block">
                    <h4>Nothing uploaded for preview.</h4>
                  </div>
                </b-col>
              </div>
            </b-card>
          </div>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card>
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">Approval required</span>
                Ready to Sign?
              </h3>
            </template>
            <b-card-text
              v-if="$route.query.type && $route.query.type == (invoice.signatories ? (invoice.signatories.length - 1) : 0)"
              class="mb-0"
            >
              <span>If the information on this invoice is accurate, e-sign this form and it will be sent to Finance Dept. for payment processing.</span>
            </b-card-text>
            <b-card-text
              v-else
              class="mb-0"
            >
              <span>If the information on this invoice is accurate, e-sign this form and it will be sent to the next assigned signatory ({{ nextApproverName }}) for signing.</span>
            </b-card-text>

            <b-form-checkbox
              v-model="secondAgree"
              value="accepted"
              unchecked-value=""
              class="custom-control-primary"
            >
              I confirm that all information is accurate on this invoice.
            </b-form-checkbox>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="secondAgree == '' || !canSubmit"
              @click="approveInvoice()"
            >
              <feather-icon
                icon="FeatherIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Sign and Approve Invoice</span>
            </b-button>
          </b-card>

          <b-card no-body>
            <b-card-header style="background: none !important;">
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25">
                  Remarks
                </b-card-title>
              </div>
            </b-card-header>

            <!-- timeline -->
            <b-card-body>
              <b-card-text>
                <b-form-textarea
                  id="h-invoice-comment"
                  v-model="extraComment"
                  trim
                  rows="3"
                  placeholder="Please leave any remarks here"
                />
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BFormTextarea, BCardText, VBTooltip, BFormCheckbox, BCardBody, BCardHeader, BCardTitle,
  BDropdown, BDropdownItem, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormTextarea,
    BCardText,
    BFormCheckbox,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      showPage: false,
      canSubmit: false,
      invoice: {},
      pOrder: {},
      poItems: [],
      comment: '',
      currentFileIndex: 1,
      selectedFileName: '',
      showPDF: false,
      imageSrc: '',
      extraComment: '',
      approverType: '',
      approverHeader: '',
      approverTitle: '',
      nextApproverName: '',
      commentValidation: false,
      commentError: '',
      secondAgree: '',
      // eslint-disable-next-line global-require
      // imageSrc: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
      description: '',
      attachmentKey: '',
      required,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  mounted() {
    this.$http.get(`purchase/vendor-payment/invoice/${this.$route.params.id}/preview`)
      .then(response => {
        this.invoice = response.data.data || {}
        if (this.invoice.attachments.length) {
          this.changeFileIndex(1)
        }
        this.pOrder = response.data.purchaseOrder || {}
        this.poItems = response.data.poItems || []
        if (this.$route.query.type || this.$route.query.type === 0) {
          let { type } = this.$route.query
          // eslint-disable-next-line radix
          type = parseInt(type)
          if (this.invoice.signatories[type].signed === true || this.resolveSignValidation(type) || this.invoice.signatories[type].user._id !== this.user._id) {
            this.$router.push({ name: 'page-not-found' })
          }
          this.extraComment = this.invoice.signatories[type].remarks
          if (type === (this.invoice.signatories.length - 1)) {
            this.approverType = this.invoice.signatories[type].type
            this.approverHeader = 'Signature Required'
            this.approverTitle = 'Approve This Invoice?'
            this.nextApproverName = ''
          } else {
            this.approverType = this.invoice.signatories[type].type
            this.approverHeader = 'Signature Required'
            this.approverTitle = 'Approve This Invoice?'
            this.nextApproverName = this.invoice.signatories[type + 1].user.name
          }
          this.canSubmit = true
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    resolveSignValidation(key) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < key; i++) {
        if (this.invoice.signatories[i].signed === false) return true
      }
      return false
    },
    async changeFileIndex(key) {
      this.currentFileIndex = key
      this.selectedFileName = this.invoice.attachments[key - 1].name
      if (this.invoice.attachments[key - 1].type.includes('pdf')) {
        // eslint-disable-next-line func-names
        // pdfViewerFrame.onload = () => {
        //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.purchaseRequest.attachments[key - 2].data)
        // }
        await this.$nextTick()
        const pdfViewerFrame2 = document.getElementById('attachmentpdfvp')
        pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.invoice.attachments[key - 1].data}`)
        this.showPDF = true
      } else {
        this.imageSrc = this.invoice.attachments[key - 1].data
        this.showPDF = false
      }
      this.showAttachment = true
    },

    approveInvoice() {
      let index = this.$route.query.type
      // eslint-disable-next-line radix
      index = parseInt(index)
      const id = this.$route.query.user
      let firstSwalMessage = ''
      let secondSwalMessage = ''
      let secondSwalTitle = 'Invoice Signed'
      if ((this.invoice.signatories.length - 1) === index) {
        firstSwalMessage = 'You are proceeding to sign this Invoice. Following this, Finance Team will start the Payment process.'
        secondSwalTitle = 'Invoice Approved'
        secondSwalMessage = `${this.invoice.invoiceID} is now ready for payment by Finance Team.`
      } else {
        firstSwalMessage = `You are proceeding to sign on this Invoice. The Invoice will then be sent to ${this.invoice.signatories[index + 1] ? this.invoice.signatories[index + 1].user.name : ''} for signing.`
        secondSwalMessage = `${this.invoice.invoiceID} will now be sent to the next assigned signatory (${this.invoice.signatories[index + 1] ? this.invoice.signatories[index + 1].user.name : ''}) for signing.`
      }
      // this.previewPage = true
      this.$swal({
        title: 'Sign this Invoice?',
        html: firstSwalMessage,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('url', window.location.origin)
            formData.append('caseID', this.invoice.invoiceID)
            formData.append('approverType', this.approverType)
            formData.append('id', id)
            formData.append('index', index)
            formData.append('remarks', this.extraComment)
            formData.append('signatoryRequired', this.invoice.signatoryRequired)
            formData.append('deptPendingSignatory', this.invoice.signatories[index + 1] ? this.invoice.signatories[index + 1].user._id : '')

            this.$http.post(`purchase/vendor-payment/invoice/${this.invoice._id}/approve`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.$swal({
                  title: secondSwalTitle,
                  html: secondSwalMessage,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(confirm => {
                    if (confirm.value) {
                      this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.invoice.purchaseRequest }, query: { tab: 'invoice' } })
                    }
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
